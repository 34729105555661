<template>
  <div class="body__error" :style="{ height: height }">
    <div class="container__error">
      <img
        :src="require('@/assets/img/mantenimiento.png')"
        draggable="false"
        height="200px"
      />
      <h1>
        <span v-if="code">{{ code }}</span> <br v-if="code" />
        {{ title }}
      </h1>
      <p
        class="page__message"
        :class="{
          'page__message--error': tipo == 'error',
          'page__message--success': tipo == 'success',
          'page__message--warn': tipo == 'warn',
        }"
      >
        {{ message }}
      </p>
      <p class="error__info">
        {{ contact }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    height: {
      type: String,
      default: "calc(100vh - 60px - 28px)",
    },
    code: {
      type: [Number, String],
      default: "",
    },
    title: {
      type: String,
      default: "Error interno de servidor",
    },
    message: {
      type: String,
      default: "",
    },
    contact: {
      type: String,
      default:
        "Comunícate con el equipo de soporte para solucionar el error presentado.",
    },
    tipo: {
      type: String,
      default: "success",
    },
  },
};
</script>

<style lang="scss" scoped>
.body__error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .container__error {
    text-align: center;
    margin: auto;
    padding: 0 4em 4em;
    img {
      width: auto;
      height: 225px;
    }

    h1 {
      margin-top: 1rem;
      font-size: 26px;
      text-align: center;
      font-weight: bold;

      span {
        font-size: 60px;
        font-weight: 900;
      }
    }
    p {
      margin-top: 1rem;
      font-size: 14px;
    }

    .page__message {
      padding: 15px 10px;
      border-radius: 10px;

      &--error {
        background: #f1aeb5;
        border: 1px solid #58151c;
        color: #58151c;
      }
      &--success {
        background: #d1e7dd;
        border: 1px solid #a3cfbb;
        color: #0a3622;
      }
      &--warn {
        background: #fff3cd;
        border: 1px solid #ffe69c;
        color: #664d03;
      }
    }
    p.error__info {
      margin-top: 15px;
      font-size: 14px;

      a {
        text-decoration: none;
        color: rgb(84, 84, 206);
      }
    }
  }
}
</style>
