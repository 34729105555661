<template>
  <div>
    <ErrorPage
      v-if="alert.active"
      v-show="alert.message"
      :title="alert.title"
      :message="alert.message"
      :contact="alert.contact"
      :tipo="alert.tipo"
    />
    <Sale v-else />
  </div>
</template>

<script>
import Sale from "./Sale.vue";
import ErrorPage from "./components/ErrorPage.vue";
import axios from "@/api/entradaslima";
import { mapGetters } from "vuex";
export default {
  components: {
    Sale,
    ErrorPage,
  },
  data() {
    return {
      loading: false,
      alert: {
        active: true,
        title: "Oops!",
        message: "",
        contact: "",
        tipo: "error",
      },
    };
  },
  computed: {
    ...mapGetters({
      idParque: "getSessionParqueId",
      idAsignacion: "getSessionIdAsignacion",
    }),
  },
  async mounted() {
    try {
      this.$store.commit("activeLoader", true);
      const { data, status } = await axios.get(
        `/parques/${this.idParque}/asignaciones/${this.idAsignacion}/estados`
      );
      this.$store.commit("activeLoader", false);

      if (status != 200)
        throw new Error("No se encontraron datos de asignación.");

      const cierreCaja = data.data
        ?.filter((item) => item.ESTADO === 1)
        ?.find((item) => item.TIPOESTADO === "CF");

      if (cierreCaja) {
        this.alert.title = "¡Caja cerrada!";
        this.alert.contact =
          "De ser necesario comunicate con un Administrador para reaperturar la caja.";
        this.alert.tipo = "warn";
        throw new Error("Estimado usuario, la caja se encuentra cerrada y por ende ya no se pueden registrar más ventas");
      }

      const aperturaCaja = data.data
        ?.filter((item) => item.ESTADO === 1)
        ?.find((item) => item.TIPOESTADO === "AI");

      if (!aperturaCaja) {
        this.alert.title = "¡Apertura tu Caja!";
        this.alert.contact = `Dirígete al enlace "Apertura de Caja" y completa el formulario para realizar ventas.`;
        this.alert.tipo = "warn";
        throw new Error(
          "Estimado usuario, la apertura de caja es requerido para poder realizar ventas."
        );
      }

      this.alert.active = false;
    } catch (error) {
      this.alert.message = error?.message;
      this.$store.commit("activeLoader", false);
    }
  },
};
</script>

<style></style>
