<template>
  <div v-if="timeString != '00:00:00'" class="wrapper-timer">
    <div class="timer-text">
      <span class="material-icons"> schedule </span>
      <p class="timer-label">
        <span>{{ firstPart[lang] }} </span>
        <span class="timer"> {{ timeString }} min </span>
        <span>{{ getSecondPart[lang] }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      firstPart: {
        en: "You have",
        es: "Tiene"
      },
      secondPart: {
        en: "to complete the sale",
        es: "para finalizar la venta",
      },
      alternativePart: {
        en: "to complete your registration",
        es: "para finalizar su registro",
      }
    };
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
      timeString: "getCurrentTimeString",
      inProgress: "getInProgress",
      permitFree: "validateFree"
    }),
    getSecondPart() {
      return this.permitFree ? this.alternativePart : this.secondPart
    }
  },
};
</script>

<style lang="scss">
.wrapper-timer {
  padding: 0;
  margin: 10px 24px;
  .timer-text {
    background: var(--color-blue);
    padding: 7px 10px;
    border-radius: 5px;
    font-family: $primary-font;
    display: flex;
    align-items: center;
    color: #fff;
    .timer-label {
      color: #fff;
      margin-bottom: 0;
      font-size: 13px;
      .timer {
        font-weight: 700;
      }
    }
    gap: 8px;
  }
}
</style>