<template>
  <v-form ref="formCobro">
    <v-container fluid>
      <v-row>
        <v-col cols="12" lg="12" sm="12">
          <h4 class="c-tertiary readable mb-1">Tipo de Pago</h4>
          <label class="d-flex subtitle-card readable mb-1"
            >Elija el tipo de cobro a realizar</label
          >
          <v-radio-group
            v-model="payloadCobro.tipoVenta"
            @change="onChangeTipoVenta"
            row
            class="mt-0"
          >
            <v-radio
              v-for="(option, idx) in tipoVentas"
              :key="option.codigo + option.text + idx"
              :label="option.text"
              :value="option.codigo"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col v-if="payloadCobro.tipoVenta != tipoEfectivo" cols="12" lg="12" sm="12">
          <h4 class="c-tertiary readable mb-2">Monto total</h4>
          <Price
            :price="montoTotal"
            style="font-size: 28px; font-weight: bold; color: rgb(0, 0, 0);"
          />
        </v-col>
        <v-col cols="12" lg="12" sm="12">
          <h4 class="c-tertiary readable mb-4">
            Detalle de Pago
          </h4>
          <template v-if="payloadCobro.tipoVenta == tipoEfectivo">
            <v-row>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th>
                        Monto Total
                        <br />
                        <label class="d-flex subtitle-card readable mb-1"
                          >Es el monto a cobrar</label
                        >
                      </th>
                      <td style="text-align: rigth;">
                        <Price
                          :key="baseIdx"
                          :price="montoTotal"
                          style="font-size: 20px; font-weight: bold; color: rgb(0, 0, 0);"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Monto Cancelación <br />
                        <label class="d-flex subtitle-card readable mb-1"
                          >Monto con el que cancela el cliente</label
                        >
                      </th>
                      <td class="pt-4 pb-1">
                        <v-text-field
                          :label="'Monto S/.'"
                          :rounded="rounded"
                          :color="color"
                          class="readable"
                          :dark="dark"
                          :background-color="bgColor"
                          :rules="[...ruleMontoCancelacion]"
                          :hide-details="false"
                          :outlined="outlined"
                          :filled="filled"
                          :dense="dense"
                          :autocomplete="autocomplete"
                          v-model="payloadCobro.montoCancelacion"
                          :solo="solo"
                          persistent-hint
                          type="number"
                          :class="{ [roundedFieldclass]: roundedFieldclass }"
                        >
                        </v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Devolución
                        <br />
                        <label class="d-flex subtitle-card readable mb-1"
                          >Vuelto del cliente</label
                        >
                      </th>
                      <td style="text-align: rigth;">
                        <Price
                          :key="baseIdx + 2"
                          :price="restante"
                          style="font-size: 20px; font-weight: bold; color: rgb(0, 0, 0);"
                        />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <v-col cols="12" lg="12" md="12" sm="12" class="pb-0">
                <label class="subtitle-card readable"
                  >Seleccione el tipo de tarjeta con el que pagó el cliente
                  (opcional)</label
                >
                <v-radio-group
                  v-model="payloadCobro.result.BRAND"
                  row
                  class="mt-0"
                >
                  <v-radio
                    v-for="(option, idx) in tiposTarjeta"
                    :key="option.codigo + option.text + idx"
                    :label="option.text"
                    :value="option.codigo"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" lg="6" md="12" sm="12">
                <v-text-field
                  :label="'ID de la transacción (opcional)'"
                  :rounded="rounded"
                  :color="color"
                  class="readable"
                  :dark="dark"
                  :background-color="bgColor"
                  :rules="rules.ruleIdTransaccion"
                  :hide-details="details"
                  :outlined="outlined"
                  :filled="filled"
                  :dense="dense"
                  :autocomplete="autocomplete"
                  v-model="payloadCobro.result.TRANSACTION_ID"
                  :solo="solo"
                  hint="Es el ID de la transacción (TRANSACTION_ID). Generalmente son 15 dígitos."
                  persistent-hint
                  counter
                  v-mask="'####################'"
                  :class="{ [roundedFieldclass]: roundedFieldclass }"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" lg="6" md="12" sm="12">
                <v-text-field
                  :label="'Código de Autorización (opcional)'"
                  :rounded="rounded"
                  :color="color"
                  class="readable"
                  :dark="dark"
                  :background-color="bgColor"
                  :rules="rules.ruleCodigoAutorizacion"
                  :hide-details="details"
                  :outlined="outlined"
                  :filled="filled"
                  :dense="dense"
                  :autocomplete="autocomplete"
                  v-model="payloadCobro.result.AUTHORIZATION_CODE"
                  :solo="solo"
                  counter
                  hint="Es el Código de Autorización (AUTHORIZATION_CODE). Generalmente son 6 dígitos."
                  v-mask="'########'"
                  persistent-hint
                  :class="{ [roundedFieldclass]: roundedFieldclass }"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </template>
        </v-col>
        <v-col
          cols="12"
          lg="12"
          sm="12"
          class="d-flex justify-content-center mt-5"
          :style="{ gap: '15px' }"
        >
          <v-btn
            @click="$emit('closeDialog')"
            depressed
            rounded
            text
            color="tertiary"
            class="readable"
            >Cerrar</v-btn
          >
          <v-btn
            :disabled="loading"
            :loading="loading"
            @click="handleFinalize"
            depressed
            rounded
            color="primary"
            class="mb-2 readable"
            min-width="200"
            >Finalizar</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Price from "./Price.vue";
import baseConfig from "@/components/forms/baseConfig";
import { mapGetters } from "vuex";
import { formatDate } from '../../../utils/date';
export default {
  components: {
    Price,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ...baseConfig,
      tipoVentas: [
        {
          text: "Efectivo",
          codigo: "EF",
        },
        {
          text: "Tarjeta",
          codigo: "TA",
        },
        {
          text: "Billeteras Digitales",
          codigo: "QR",
        },
      ],
      tiposTarjeta: [
        {
          text: "Visa",
          codigo: "visa",
        },
        {
          text: "Diners",
          codigo: "diners",
        },
        {
          text: "MasterCard",
          codigo: "mastercard",
        },
        {
          text: "Amex",
          codigo: "amex",
        },
        {
          text: "Otros",
          codigo: "otros",
        },
      ],
      baseIdx: 545454546565,
      tipoEfectivo: "EF",
      payloadCobro: {
        tipoVenta: "EF",
        montoCancelacion: "",
        montoDevolucion: "",
        result: {
          AUTHORIZATION_CODE: "",
          TRANSACTION_ID: "",
          BRAND: "",
        },
      },
      rules: {
        ruleIdTransaccion: [
          (v) =>
            !v ||
            (v && v.length >= 15) ||
            "La logintud debe ser (15) dígitos como mínimo",
        ],
        ruleCodigoAutorizacion: [
          (v) =>
            !v ||
            (v && v.length >= 6) ||
            "La logintud debe ser (6) dígitos como mínimo",
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      montoTotal: "getArticlesTotal",
    }),
    restante() {
      this.baseIdx += 100;
      if (!this.payloadCobro.montoCancelacion) return 0;
      return this.payloadCobro.montoCancelacion - this.montoTotal;
    },
    ruleMontoCancelacion() {
      return [(v) => !v || (v && Number(v) >= this.montoTotal) || "Monto incorrecto"];
    },
  },
  methods: {
    onChangeTipoVenta(ev) {
      console.log(ev);
      this.payloadCobro.montoCancelacion = "";
      this.payloadCobro.montoDevolucion = "";
      this.payloadCobro.result.AUTHORIZATION_CODE = "";
      this.payloadCobro.result.TRANSACTION_ID = "";
      this.payloadCobro.result.BRAND = "";
    },
    validateForm() {
      return this.$refs.formCobro.validate();
    },
    handleFinalize() {
      if (!this.validateForm()) return;
      this.$emit("finalize", {
        formaPago: this.payloadCobro.tipoVenta,
        importePago: parseFloat(this.payloadCobro.montoCancelacion),
        importeVuelto: this.restante,
        metadata: {
          ...this.payloadCobro.result,
          TRANSACTION_DATE: formatDate(new Date(), "YYMMDDHHmmss")
        }
      });
    },
  },
};
</script>

<style></style>
