<template>
  <v-container
    fluid
    :class="
      `${!getVisibilitySaleView ? 'h-100 d-flex align-items-center' : ''}`
    "
  >
    <v-row v-if="getVisibilitySaleView">
      <v-col cols="12" lg="3" md="4" sm="12" class="col-stepper">
        <Stepper></Stepper>
      </v-col>
      <v-col cols="12" lg="7" md="5" sm="12">
        <v-stepper class="elevation-0" v-model="step">
          <v-stepper-items>
            <Step1 :key="1560"></Step1>
            <Step2 :key="1580"></Step2>
            <Step3 :key="1590"></Step3>
            <Step4 ref="formStep" :key="1600"></Step4>
          </v-stepper-items>
        </v-stepper>
      </v-col>
      <v-col cols="12" lg="2" md="3" sm="12">
        <TicketResume
          @goToPay="validateForm"
          :loading="loadingBtn"
        ></TicketResume>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12" lg="12" class="h-100">
        <div class="d-flex justify-content-center mt-3">
          <img
            width="200"
            :src="webMaintanceImg"
            draggable="false"
            :alt="getStatusDevelopment[lang]"
          />
        </div>
        <p class="text-error-message text-center mt-3 readable">
          {{ getStatusDevelopment[lang] }}
        </p>
      </v-col>
    </v-row>

    <DialogErrorButacas />

    <Dialog
      :dialog="dialogPay"
      :title="titleDialogPay[lang]"
      width="800"
      @closeDialog="dialogPay = false"
    >
      <template v-slot:content-dialog>
        <FormVenta @closeDialog="dialogPay = false" @finalize="savePurchase" />
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Observe } from "@/libs/mutationObserver";
import { setColorsApplication } from "@/plugins/colors";
import TicketResume from "./components/TicketResume.vue";
import Stepper from "./components/Stepper.vue";
import Step1 from "./components/Step1.vue";
import Step2 from "./components/Step2.vue";
import Step3 from "./components/Step3.vue";
import Step4 from "./components/Step4.vue";
import DialogErrorButacas from "./components/DialogErrorButacas.vue";
import Dialog from "@/components/Dialog";
import FormVenta from "./components/FormVenta.vue";

export default {
  components: {
    TicketResume,
    Stepper,
    Step1,
    Step2,
    Step3,
    Step4,
    DialogErrorButacas,
    Dialog,
    FormVenta,
  },
  computed: {
    ...mapGetters({
      validateAdquiriente: "getValidateAdquiriente",
      park: "getCurrentParkByDomain",
      lang: "getLanguage",
      categories: "getCategoriesPark",
      datesList: "getDatesList",
      step: "getStep",
      paymentList: "getPaymentList",
      user: "getSessionUser",
      permitAnticipated: "validateAnticipated",
      permitFree: "validateFree",
      getVisibilitySaleView: "getVisibilitySaleView",
      getStatusDevelopment: "getStatusDevelopment",
      accTools: "getAccesibilityTool",
      modoInvitado: "permiteComprarModoInvitado",
    }),
  },
  data() {
    return {
      webMaintanceImg: require("@/assets/img/icons/web-maintenance.png"),
      titleDialogPay: {
        en: "Payment Information",
        es: "Información de Pago",
      },
      errors: {
        needUser: {
          en: `You must log in with an existing account to continue registration`,
          es: `Debes iniciar sesión con una cuenta existente para continuar con el registro`,
        },
        incomplete: {
          en: "Please, correctly complete all the fields of the form",
          es:
            "Por favor, completa correctamente todos los campos del formulario",
        },
        terms: {
          en: "You must accept the terms and conditions to continue",
          es: "Debes aceptar los términos y condiciones para continuar",
        },
        validateDocument: {
          en: "You must perform the search to validate your identity document",
          es:
            "Debes realizar la busqueda para validar tu documento de identidad",
        },
        noPayments: {
          en:
            "We are sorry, no payment method was found to process your purchase, please try again later",
          es:
            "Lo sentimos, no se encontró un medio de pago para procesar su compra, intentelo más tarde",
        },
        paymentError: {
          en:
            "We are sorry, an error is occurring with the selected payment method, please try again later.",
          es:
            "Lo sentimos, está ocurriendo un error con el medio de pago seleccionado, por favor intentelo nuevamente más tarde.",
        },
        serverError: {
          en:
            "We are sorry, a server error is occurring, please try again later",
          es:
            "Lo sentimos, está ocurriendo un error con el servidor, por favor intentelo nuevamente.",
        },
        noCompanionsRegister: {
          en:
            "The registration of your companions could not be completed, please try again",
          es:
            "No se pudo completar el registro de sus acompañantes, intentelo nuevamente",
        },
        errorSave: {
          en:
            "Your purchase could not be completed. Dear user, please check your internet connection before proceeding with the payment.",
          es:
            "No se pudo completar tu compra. Estimado usuario por favor revisa tu conexión a internet antes de continuar con el pago.",
        },
        errorInvitedMode: {
          en: "Login is required for this purchase.",
          es: "El inicio de sesión es requerido para esta compra.",
        },
      },
      loadingBtn: false,
      formPayment: false,
      observer: null,
      dialogPay: false,
    };
  },
  watch: {
    getVisibilitySaleView(val) {},
    step(val) {
      this.accTools.restart(this.lang);
      this.closeModalPay();
    },
  },
  mounted() {
    this.$store.dispatch("getUserIP");
    this.accTools.restart(this.lang);
  },
  methods: {
    ...mapActions({
      fetchMyShoppingsList: "fetchMyShoppingsList",
      fetchPaymentKeys: "fetchPaymentKeys",
      generateVisaNetToken: "generateVisaNetToken",
      updatePurchaseStatusFree: "updatePurchaseStatusFree",
      updatePurchaseStatus: "updatePurchaseStatus",
      // insertHeaderPurchase: "insertHeaderPurchase",
      // insertAllPurchaseDetail: "insertAllPurchaseDetail",
      // registerCompanionList: "registerCompanionList",
      registerPurchase: "registerPurchase",
      generateVisaNetSession: "generateVisaNetSession",
      openVisaNetForm: "openVisaNetForm",
      pauseTimer: "pauseTimer",
      unPauseTimer: "unPauseTimer",
      destroyAdvance: "destroyAdvance",
      cancelPurchase: "cancelPurchase",
    }),

    confirmLeave() {
      return window.confirm(
        "Do you really want to leave? you have unsaved changes!"
      );
    },
    confirmStayInDirtyForm() {
      return this.step > 1;
    },
    beforeWindowUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        const confirmationMessage = {
          en: "Do you really want to leave? Your changes will be lost!",
          es: "¿De verdad quieres irte? ¡Tus cambios se perderán!",
        };
        e.returnValue = confirmationMessage[this.lang];
      } else {
        this.destroyAdvance();
      }
    },
    async validateForm() {
      this.$store.state.sale.errors = [];

      /* if(!this.modoInvitado && !this.user) {
        // No permite modo invitado y no hay sesión
        this.$store.commit("changeSnackbar", true);
        this.$store.state.sale.errors.push(this.errors.errorInvitedMode);
        return;
      } */

      const formContainer = this.$refs.formStep.$refs.formPayment;
      const form = this.$refs.formStep.$refs.formPayment.$refs.formSale;
      const payload = this.$refs.formStep.$refs.formPayment._data.payload;

      this.$store.state.sale.errors = [];

      if (!this.user && (this.permitFree || this.permitAnticipated)) {
        // Permit free and anticipated purchase
        this.$store.commit("changeSnackbar", true);
        this.$store.state.sale.errors.push(this.errors.needUser);
        return;
      }

      if (!form.validate()) {
        // validate form
        this.$store.commit("changeSnackbar", true);
        this.$store.state.sale.errors.push(this.errors.incomplete);
        return;
      }

      if (!payload.terms) {
        // validate terms and conditions
        this.$store.commit("changeSnackbar", true);
        this.$store.state.sale.errors.push(this.errors.terms);
        return;
      }

      if (this.validateAdquiriente == true) {
        if (
          !payload.documentType &&
          !payload.document &&
          !payload.names &&
          !payload.lastnames /* || !payload.validate */
        ) {
          // validar  documento identidad adquiriente
          this.$store.commit("changeSnackbar", true);
          this.$store.state.sale.errors.push(this.errors.validateDocument);
          return;
        }
        /* if (!payload.validate) {
          // validar  documento identidad adquiriente
          this.$store.commit("changeSnackbar", true);
          this.$store.state.sale.errors.push(this.errors.validateDocument);
          return;
        } */
      }

      if (this.permitFree) {
        // Gratuitos

        this.loadingBtn = true;
        const validate = await formContainer.validatePreSaveFree(); // validar acompaniantes
        this.loadingBtn = false;

        if (!validate) return;

        // Pausar timer si está listo para guardar registro
        this.pauseTimer();

        // Save header purchase
        this.$store.commit("activeLoader", true);
        const data = await this.registerPurchase(payload); // this.insertHeaderPurchase(payload);
        this.$store.commit("activeLoader", false);

        if (!data?.success) {
          // If error, return
          this.destroyAdvance();
          this.$store.commit("changeSnackbar", true);
          this.$store.state.sale.errors.push(
            data?.message || this.errors.serverError
          );
          return;
        }

        const { IDCOMPRA, DIAS, RECURRENTE } = data;

        if (!IDCOMPRA || IDCOMPRA == "0") {
          // If error in purchaseID, return
          this.destroyAdvance();
          this.$store.commit("changeSnackbar", true);
          this.$store.state.sale.errors.push(
            data?.message || this.errors.serverError
          );
          return;
        }

        // Update Purchase status
        await this.updatePurchaseStatusFree({
          purchaseId: IDCOMPRA,
          paymentType: payload.paymentType,
        });
        // await this.fetchMyShoppingsList();

        this.$store.commit("activeLoader", false);
        this.destroyAdvance();
        this.$router.push({
          name: "TicketDetail",
          params: { purchaseId: IDCOMPRA, parkId: this.park.IDPARQUE },
        });
      } else {
        this.openModalPay();
        /* // Payment
        if (!this.paymentList || this.paymentList.length == 0) {
          this.$store.commit("changeSnackbar", true);
          this.$store.state.sale.errors.push(this.errors.noPayments);
          return;
        }

        this.$store.commit("activeLoader", true);
        const paymentKeys = await this.fetchPaymentKeys(payload.paymentType);
        this.$store.commit("activeLoader", false);
        if (!paymentKeys || paymentKeys.length == 0) {
          this.$store.commit("changeSnackbar", true);
          this.$store.state.sale.errors.push(this.errors.noPayments);
          return;
        }

        // Pausar timer si está listo para guardar y pagar
        this.pauseTimer(); */

        /* this.$store.commit("activeLoader", true);
        const data = await this.registerPurchase(payload); // this.insertHeaderPurchase(payload);
        this.$store.commit("activeLoader", false);

        if (!data?.success) {
          this.destroyAdvance();
          this.$store.commit("changeSnackbar", true);
          this.$store.state.sale.errors.push(
            data?.message || this.errors.serverError
          );
          return;
        }

        const { IDCOMPRA, DIAS, RECURRENTE } = data;

        if (!IDCOMPRA || IDCOMPRA == "0") {
          // If error in purchaseID, return
          this.destroyAdvance();
          this.$store.commit("changeSnackbar", true);
          this.$store.state.sale.errors.push(
            data?.message || this.errors.serverError
          );
          return;
        } */

        /* let keys = {};

        paymentKeys.forEach((item) => {
          keys[`${item.LLAVE}`] = item.VALOR;
        });

        this.$store.commit("activeLoader", true);
        const token = await this.generateVisaNetToken(keys);
        this.$store.commit("activeLoader", false);

        if (!token) {
          this.$store.commit("changeSnackbar", true);
          this.$store.state.sale.errors.push(this.errors.paymentError);
          await this.cancelPurchase({
            purchaseId: IDCOMPRA,
            paymentType: payload.paymentType,
            noMessage: true,
          });
          this.destroyAdvance();
          return;
        }

        this.$store.commit("activeLoader", true);
        const session = await this.generateVisaNetSession({
          merchantId: keys.VISANET_MERCHANT,
          token: token,
          email: payload.email,
        });
        this.$store.commit("activeLoader", false);

        if (!session) {
          this.$store.commit("changeSnackbar", true);
          this.$store.state.sale.errors.push(this.errors.paymentError);
          await this.cancelPurchase({
            purchaseId: IDCOMPRA,
            paymentType: payload.paymentType,
            noMessage: true,
          });
          this.destroyAdvance();
          return;
        }

        const { sessionKey } = session;

        const dto = {
          sessionToken: sessionKey,
          merchantId: keys.VISANET_MERCHANT,
          vUsername: keys.VISANET_USERNAME,
          vPassword: keys.VISANET_PASSWORD,
          purchaseId: IDCOMPRA,
          paymentType: payload.paymentType,
          names: payload.names,
          lastNames: payload.lastnames,
          email: payload.email,
        };

        this.$store.commit("activeLoader", true);
        await this.openVisaNetForm(dto);
 
        setColorsApplication(this);
        setTimeout(() => {
          this.$store.commit("activeLoader", false);
          this.observer = Observe(
            "#visaNetJS",
            {
              attributesList: ["style"], // Only the "style" attribute
              attributeOldValue: true, // Report also the oldValue
            },
            (m) => this.clearPurchase(m, IDCOMPRA, payload)
          );
        }, 1200); */
      }
    },
    async openModalPay() {
      this.dialogPay = true;
    },
    async closeModalPay() {
      this.dialogPay = false;
    },
    async savePurchase(payloadVenta) {
      try {
        const payload = this.$refs.formStep.$refs.formPayment._data.payload;
        this.$store.commit("activeLoader", true);
        const data = await this.registerPurchase({
          ...payload,
          formaPago: payloadVenta?.formaPago,
          importePago: payloadVenta?.importePago,
          importeVuelto: payloadVenta?.importeVuelto,
        });
        this.$store.commit("activeLoader", false);

        if (!data?.success) {
          this.destroyAdvance();
          this.closeModalPay();
          this.$store.commit("changeSnackbar", true);
          this.$store.state.sale.errors.push(
            data?.message || this.errors.serverError
          );
          return;
        }

        const { IDCOMPRA, DIAS, RECURRENTE } = data;

        if (!IDCOMPRA || IDCOMPRA == "0") {
          // If error in purchaseID, return
          this.destroyAdvance();
          this.closeModalPay();
          this.$store.commit("changeSnackbar", true);
          this.$store.state.sale.errors.push(
            data?.message || this.errors.serverError
          );
          return;
        }

        // Actualizar Estado
        this.$store.commit("activeLoader", true);
        const responseEstado = await this.updatePurchaseStatus({
          purchaseId: IDCOMPRA,
          metadata: payloadVenta.metadata,
        });
        this.$store.commit("activeLoader", false);

        if (!responseEstado) {
          // If error in purchaseID, return
          this.destroyAdvance();
          this.closeModalPay();
          this.$store.commit("changeSnackbar", true);
          this.$store.state.sale.errors.push(this.errors.serverError);
          return;
        }

        // Redirigir a vista de detalle
        this.$router.push({
          name: "TicketDetail",
          params: { purchaseId: IDCOMPRA, parkId: this.park.IDPARQUE },
        });

      } catch (error) {
        this.$store.commit("activeLoader", false);
        console.log(error);
      }
    },
    async clearPurchase(m, purchaseId, payload) {
      if (m.target.style.display == "none") {
        //window.addEventListener("beforeunload", this.beforeWindowUnload);
        // validate on close
        this.$store.commit("activeLoader", true);
        await this.cancelPurchase({
          purchaseId: purchaseId,
          paymentType: payload.paymentType,
        });
        this.destroyAdvance();
        if (this.observer) {
          // deconectado
          this.observer.disconnect();
        }
        console.log("Entrando a cancelar");
        // debugger;
        this.$store.commit("activeLoader", false);
        location.reload();
      } else {
        // Form abierto
        //window.removeEventListener("beforeunload", this.beforeWindowUnload);
      }
    },
  },
};
</script>

<style lang="scss">
/* .v-main__wrap {
  display: flex;
  align-items: center;
  font-family: $primary-font;
} */

.aforo-info {
  display: flex;
  span {
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    min-width: 50px;
    max-width: 50px;
    text-align: center;
  }
  span.divider {
    max-width: 10px;
    display: flex;
    justify-content: center;
  }
  span.title-type {
    font-size: 8px;
  }
}

@media screen and (max-width: 600px) {
  .aforo-info span {
    font-size: 12.5px;
  }
  .v-stepper__content {
    padding: 24px 10px 16px 10px !important;
  }
  .col-stepper {
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 5;
    .card-stepper {
      display: flex !important;
      flex-flow: row wrap;
    }
    .link-to-return {
      margin: 0 !important;
      padding-left: 5px !important;
      order: 1;
      font-size: 14px !important;
      flex: 1;
    }
    .wrapper-timer {
      order: 3;
      margin: 0;
      width: 100%;
      padding: 10px 0 !important;
      .timer-label {
        font-size: 13px;
      }
    }
    .quiz-btn {
      order: 2;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      .faq-button {
        margin: 0;
        padding: 6px !important;
        width: auto !important;
        span {
          display: none;
        }
      }
    }
    .horizontal-step {
      margin-top: 10px !important;
      order: 4;
      flex-basis: 100%;
    }
  }
}

.float-btn-steppers {
  @media screen and (max-width: 600px) {
    position: fixed;
    bottom: 42px;
    left: 0;
    width: 100%;
    z-index: 5;
    background: #fff;
    padding: 6px 15px;
    box-shadow: -1px 4px 36px -16px rgb(0 0 0 / 30%);
  }
}

.float-total {
  @media screen and (max-width: 600px) {
    position: fixed;
    bottom: 42px;
    left: 0;
    z-index: 5;
    display: flex;
    gap: 5px;
    padding: 0 10px;
    height: 54px;
    align-items: center;
    .total {
      display: none !important;
    }
    .price {
      font-size: 24px !important;
    }
  }
  @media screen and (max-width: 350px) {
    .price {
      font-size: 20px !important;
    }
  }
}
.text-error-message {
  font-size: 20px;
  font-weight: 700;
  color: var(--color-turquoise);
}

@media screen and (max-width: 600px) {
  .accesibility-bar {
    bottom: calc(54px + 42px) !important;
  }
}
</style>
